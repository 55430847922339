import Ractive from 'ractive';
import $ from "jquery";
import TweenMax from "gsap";
import CircleLoader from './circle-loader';

export default Ractive.extend({
    template: `
    <div class="preloader_main">
      <div class="preloader_body">
        <div class="loader_main_icon">
          <div class="icon_outer">
          </div>
          <div class="icon_inner">
            <img src="./images/headphones.svg" alt="">
          </div>
          <div class="icon_text">
            {{loaderPercentage}}%
          </div>
          <CircleLoader progress="{{loaderPercentage}}" loaderColor={{loaderColor}} />
        </div>

        <div class="preloader_text">
          <h1>Turn on your audio</h1>
          <p>For the best experience, please set your speakers or headphones volume to an enjoyable level.</p>
          <div class="btn-group enter" >
           <a href="{{redirectUrl}}" id="enter_button" class="btn white disabled" title="Enter Website" on-click="enterClick">Enter</a>
          </div>
        </div>
      </div>
    </div>
    `,
    data: {
      loaderPercentage: 0,
      loaderColor: '#10cfc9',
      redirectUrl: '#guides'
    },
    components: {
      CircleLoader: CircleLoader
    },
    oninit() {
        console.log('404 init');

    },

    onrender () {

      const images = [
      // Home images
      './images/map_panel_2048.jpg',
      './images/lauren_home_portrait.jpg',
      './images/saiful_home_portrait.jpg',
      './images/uma_home_portrait.jpg',
      './images/map_panel_1024.jpg',
      './images/Lauren_home_landscape.jpg',
      './images/saiful_home_landscape.jpg',
      './images/uma_home_landscape.jpg',
      // Guide selector images
      './images/lauren/lauren_avatar.jpg',
      './images/saiful/saiful_avatar.jpg',
      './images/uma/uma_avatar.jpg',
      './images/lauren/lauren_guide_select.jpg',
      './images/saiful/saiful_guide_select.jpg',
      './images/uma/uma_guide_select.jpg',
      // Lauren location images
      './images/lauren/shacklewell_desktop.jpg',
      './images/lauren/shacklewell_mobile.jpg',
      './images/lauren/docklands_basketball_desktop.jpg',
      './images/lauren/docklands_basketball_mobile.jpg',
      './images/lauren/museaum_docklands_desktop.jpg',
      './images/lauren/museaum_docklands_mobile.jpg',
      './images/lauren/moo_desktop.jpg',
      './images/lauren/moo_mobile.jpg',
      // Saiful location images
      './images/saiful/here_east_desktop.jpg',
      './images/saiful/here_east_mobile.jpg',
      './images/saiful/ridley_road_desktop.jpg',
      './images/saiful/ridley_road_mobile.jpg',
      './images/saiful/steak_desktop.jpg',
      './images/saiful/steak_mobile.jpg',
      './images/saiful/wake_up_desktop.jpg',
      './images/saiful/wake_up_mobile.jpg',
      './images/saiful/white_chaple_desktop.jpg',
      './images/saiful/white_chaple_mobile.jpg',
      // Uma location images
      './images/uma/col_road_desktop.jpg',
      './images/uma/col_road_mobile.jpg',
      './images/uma/crate_pizza_desktop.jpg',
      './images/uma/crate_pizza_mobile.jpg',
      './images/uma/gallery_desktop.jpg',
      './images/uma/gallery_mobile.jpg',
      './images/uma/hub_dance_desktop.jpg',
      './images/uma/hub_dance_mobile.jpg',
      './images/uma/retro_desktop.jpg',
      './images/uma/retro_mobile.jpg',
      './images/uma/vic_park_desktop.jpg',
      './images/uma/vic_park_mobile.jpg',
      // Campuses
      './images/campuses/Docklands-600x1024-2x.jpg',
      './images/campuses/Docklands-390x254-2x.jpg',
      './images/campuses/Stratford-600x1024-2x.jpg',
      './images/campuses/Stratford-390x254-2x.jpg',
      './images/campuses/USS-600x1024-2x.jpg',
      './images/campuses/USS-390x254-2x.jpg'];

      this.on('enterClick', ()=> {
        this.fire('enterClicked1')
      });

      let progress = 0;

      this.loadImages(images).then((images) => {
           this.set('loaderPercentage', 100);
           $(this.find('#enter_button')).removeClass('disabled');
           console.log('images',images)
       }).catch((err) => console.error(err));
      this.fire('hideMapIcon');
    },

    loadImages(images) {
      var self = this;
      let progress = 0;
      let obj = {color: 'white'}

      const el =  self.findComponent('CircleLoader')//document.querySelector('progress-ring');
       return new Promise((resolve, reject) => {
           (function loadEach(images, index) {
               if (index < images.length) {
                   let img = new Image();
                   img.src = images[index];
                   images[index] = img;
                   images[index].onload = function() {
                      progress = index/images.length*100;
                      self.set('loaderPercentage', Math.round(progress));
                      if (progress > 25 && progress <= 50) {
                        TweenMax.to(obj, .3, { color: '#92e055', onUpdate: ()=> {
                          self.set('loaderColor', obj.color);
                        }});

                      }
                      else if (progress > 50 && progress <= 75) {
                        TweenMax.to(obj, .3, { color: '#ff90b8', onUpdate: ()=> {
                          self.set('loaderColor', obj.color);
                        }});
                      }
                      else if (progress > 75) {
                        TweenMax.to(obj, .3, { color: '#ffa710', onUpdate: ()=> {
                          self.set('loaderColor', obj.color);
                        }});
                      }

                       loadEach(images, ++index);
                   };
                   images[index].onerror = (err) => reject(err);
               } else {
                   resolve(images);
               }
           })(images, 0)
       });
   },

    onteardown() {
        console.log('404 teardown');
    }
  });

import Ractive from 'ractive';
import Router from '../../src/ractive-router';
import $ from "jquery";


export default Ractive.extend({
    template: `
    <div class="map_cards_inner {{class}}"  data-tags={{ data.tags }} data-index={{index}}>
        <div class="card">
            <div class="card_image">
                {{#if data.isBlue && data.card.slides}}
                    <div class="slider-wrapper slider-wrapper-main-slide card-slide" data-slide="{{ data.ID }}" id="slider{{ data.ID }}">
                        {{#each data.card.slides }}

                        <div on-click="mapCardImageClicked">

                           {{#if image }}
                                <div class="loc-slide" style="background-image:url({{ image }})" src="" >
                                    <div data-pop="{{ data.ID }}" class="img-slide-hover"><img src="./images/expand-small-white.svg"/></div>
                                </div>
                            {{else}}
                            <div class="loc-slide youtube" data-pop="{{ data.id }}">
                                <div data-pop="{{ data.ID }}" class="vid-slide-hover test class">
                                  <img src="./images/play-small-white.svg"/>
                                </div>
                                <img class="youtube-thumb" src="https://img.youtube.com/vi/{{video_id}}/hqdefault.jpg"/>
                            </div>
                            {{/if}}
                          </div>
                        {{/each}}
                    </div>
                {{else}}
                    <img src="{{data.card.image}}" alt="{{data.card.title}} Image">
                {{/if}}


                <div class="image_overlay">
                {{#if !data.isBlue}}
                    <button class="play_btn" data-index={{index}} data-link={{ data.defaultguidevideoimg1link }} data-id={{data.locationvideo}} on-click="gotoVideo"><img src="{{data.card.mapmarkerplay}}" alt="Play {{data.card.title}} Video"></button>
                    <a href="" class="button {{data.card.color}}" data-link={{ data.defaultguidevideoimg1link }} data-index={{index}} data-id={{data.locationvideo}} on-click="gotoVideo">To {{data.card.title}}</a>
                {{/if}}
                </div>
            </div>
            <div class="card_title">
                <div class="icon"><img src="{{data.card.logo}}" alt="{{data.card.title}}"></div>
                <h3>{{data.card.title}}</h3>
            </div>
            <div class="card_description">
                <p>{{data.card.description}}</p>

                <a href="{{data.card.website}}" class="btn" target="_blank" title="Visit {{data.card.title}}">{{data.card.linkText}}</a>
            </div>
        </div>
    </div>
    `,

    onrender () {

      var slider = this.find('.slider-wrapper-main-slide');
        $(slider).slick({
            dots: true,
            infinite: true,
            arrows:true,
            prevArrow:"<button type='button' class='slick-prev pull-left'><</button>",
            nextArrow:"<button type='button' class='slick-next pull-right'>></button>",
            speed: 300,
            slidesToShow: 1
        });

      this.on({
        'gotoVideo': (e)=> {

          e.original.preventDefault();
          var videoId = $(e.node).data('id');
          var videoIdRouteLINK = $(e.node).data('link');
          var videoIdRoute = videoIdRouteLINK.split('/');
          // console.log(videoIdRoute[1] +'jaja');
          //
          //
          // var tags = this.get('parentGlobals.guides[' + this.get('routeParams.id') + '].locations[' + $(e.node).data('index') +'].tags');
          //
          // var tagIndex = Math.floor(Math.random() * tags.length);
          // var transVidId = 0;
          //
          // for(var i = 0; i < this.get('parentGlobals.transitionVideos').length; i++) {
          //   for(var j = 0; j < this.get('parentGlobals.transitionVideos')[i].tags.length; j++) {
          //     if (tags[tagIndex] === this.get('parentGlobals.transitionVideos')[i].tags[j]) {
          //       transVidId = this.get('parentGlobals.transitionVideos')[i].tvidId;
          //     }
          //   }
          // }
          //Router.go('#video/' + this.get('routeParams.id') + '/' + videoId + '/' + transVidId);
            localStorage.removeItem("ActiveFilters");

          Router.go('#video/' + videoIdRoute[1] + '/' + videoId);
            //window.location.reload(true);
        },
        'mapCardImageClicked':(e)=> {
           this.fire('mapCardClicked', {card: this.get(), slickIndex:$(e.node).data('slick-index')})
        }
      });

    },
    onteardown () {
      this.off();

    }
});

import Ractive from 'ractive';
import $ from "jquery";
import Router from '../../src/ractive-router';
import TweenMax from "gsap";

export default Ractive.extend({
    template: `
        <div id="guide-selector" class="hidden" >
          <div class="guide-selector-wrapper">
            <div class="close-button" on-click="close">
              <span class="label">Close</span>
              <span class="icon"><img src="./images/close_button.svg" title="Close" alt="Close" /></span>
            </div>

            <div class="guide_blocks flex">

              <div class="block flex">
                <div class="block_image" data-index="4" data-name="allmaps" on-click="switchGuide">

                {{#if routeParams.id == 4 }}
                    <a on-click="close"  data-name="neutral" title="To the map"><img src="./images/map_with_pin.png" alt="Neutral pin"></a>
                    {{else}}
                  <a on-click="switchGuide"  data-name="neutral" title="To the map"><img src="./images/map_with_pin.png" alt="Neutral pin"></a>
                {{/if}}

                </div>
                <div class="block_content primary-blue">
                  <h3>All Locations</h3>

                  {{#if routeParams.id == 4 }}
                    <a class="btn-group btn" on-click="close" data-name="neutral"  title="To the map">To the map</a>
                  {{else}}
                   <a class="btn-group btn" data-name="neutral" on-click="switchGuide" title="To the map">To the map</a>
                  {{/if}}

                </div>
              </div>

              <div class="block flex">
                <div class="block_image" data-index="1" on-click="playGuideIntroVideo">
                  <img src="./images/lauren/lauren_guide_select.jpg" alt="Play Lauren">
                </div>
                <div class="block_content primary-green">
                  <button class="play_btn" data-index="1" on-click="playGuideIntroVideo"><img src="./images/play_arrow.svg" /></button>
                  <h3>Lauren</h3>
                  <div class="btn-group btn" data-name="lauren" on-click="switchGuide" title="To the map">To the map</div>
                </div>
              </div>

              <div class="block flex">
                <div class="block_image" data-index="2" on-click="playGuideIntroVideo">
                  <img src="./images/saiful/saiful_guide_select.jpg" alt="Play Saiful">
                </div>
                <div class="block_content primary-pink">
                  <button class="play_btn" data-index="2" on-click="playGuideIntroVideo"><img src="./images/play_arrow.svg" /></button>
                  <h3>Saiful</h3>
                  <div class="btn-group btn" data-name="saiful" on-click="switchGuide" title="To the map">To the map</div>
                </div>
              </div>

              <div class="block flex">
                <div class="block_image" data-index="3" on-click="playGuideIntroVideo">
                  <img src="./images/uma/uma_guide_select.jpg" alt="Play Uma">
                </div>
                <div class="block_content primary-orange">
                  <button class="play_btn" data-index="3" on-click="playGuideIntroVideo"><img src="./images/play_arrow.svg" /></button>
                  <h3>Uma</h3>
                  <div class="btn-group btn" data-name="uma" on-click="switchGuide"  title="To the map">To the map</div>
                </div>
              </div>

            </div>
          </div>
        </div>
    `,
    oninit() {

    },

    onrender () {
      this.on({
        'close': ()=> {
          this.close();
        },
        'switchGuide':(e) => {
          this.fire('guideSelected', $(e.node).data('name'));
            localStorage.removeItem("ActiveFilters");
        },
        'playGuideIntroVideo':(e) => {
          // console.log($(e.node).data('index'), this.get('parentGlobals.guides[' + $(e.node).data('index') + '].profileVideo'))
          Router.go('#video/' + $(e.node).data('index') +'/'+ this.get('parentGlobals.guides[' + $(e.node).data('index') + '].profileVideo'));
        }
      });
    },

    close () {
      TweenMax.to("#guide-selector", 0.1, {
        opacity: 0,

        onComplete: ()=> {
          TweenMax.set("#guide-selector", {delay: 0.1, opacity: 1, scale:1, className:"hidden"})
        }
      });
    },

    show() {
      $(this.find('#guide-selector')).removeClass('hidden');
      TweenMax.from(".block", {
        opacity: 0,
        from: "center",
        x: 50,
        scale:1.2,
        ease: 'power4',
        stagger: 0.01 // 0.1 seconds between when each ".box" element starts animating
      });
    },

    onteardown() {
      this.off();
    }
});

import Ractive from 'ractive';

export default Ractive.extend({
    template: `
        <div class="page_404">
            <div class="container_inner">
                <h1>404</h1>
                <h3>Page not found</h3>
                <p>Whoops, we can’t seem to  find the page you’re looking for. Head back home to choose a new adventure</p>
                <div class="btn_group">
                    <a href="#guides" class="btn white" title="Back home<">Back home</a>
                </div>
            </div>
        </div>
    `,
    oninit() {
        console.log('404 init');
    },
    onrender () {
        setTimeout(()=> {
          this.fire('hideAvatar');
        },100);

    },
    onteardown() {
        console.log('404 teardown');
    }
});
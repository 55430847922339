import Ractive from 'ractive';
import $ from "jquery";
import TweenMax from "gsap";
import Router from "../../src/ractive-router";

export default Ractive.extend({
    template: `
      <div class="site_logo">
         <a href="#guides"><img src="images/logo.svg" alt="University of East London" /></a>
      </div>

      <div class="site_avatar mobile_hidden">
        <div class="avatar hidden">
          <div class="bg-circle {{guideClass}}">
            <img src="{{guideImg}}" alt="Guide Avatar" on-click="avatarClicked"/>
          </div>
          <img src="{{switchImg}}" class="switch" alt="Switch Guide" on-click="avatarClicked"/>
        </div>
        <div class="map_mobile_icon" on-click="mapIconClicked">
          <svg width="27px" height="40px" viewBox="0 0 27 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Map icon</title><g id="icn-/-Map-icon" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><path d="M13.2964,0 C9.76997402,0 6.38797991,1.40086046 3.89442019,3.89442019 C1.40086046,6.38797991 0,9.76997402 0,13.2964 C0,23.3795022 13.2964,40 13.2964,40 C13.2964,40 26.5928,23.3795022 26.5928,13.2964 C26.5928,5.95300512 20.6397949,0 13.2964,0 Z M13.2964,20.8864267 C9.10454402,20.8864267 5.70637333,17.488256 5.70637333,13.2964 C5.70637333,9.10454402 9.10454402,5.70637333 13.2964,5.70637333 C17.488256,5.70637333 20.8864267,9.10454402 20.8864267,13.2964 C20.8864267,15.3094006 20.086767,17.2399543 18.6633606,18.6633606 C17.2399543,20.086767 15.3094006,20.8864267 13.2964,20.8864267 Z" id="Map-icon" fill="#10CFC9" fill-rule="nonzero"></path></g></svg>
        </div>
      </div>
    `,
    data: {
      guideImg: "",
      switchImg: "",
      guideIndex: 1,
      guideClass: ""
    },
    oninit() {
        console.log('Menu init');

    },
    onrender () {
      this.on({
        'avatarClicked': () => {
          this.fire('avatarClicked1')
        },
        'mapIconClicked': ()=> {
          var id = this.get('guideIndex');
          this.fire('mapIconClicked1', {guideIndex: id ? id : 1})
        }
      });
        $('.site_logo').on('click', function() {
            localStorage.removeItem("ActiveFilters");
            Router.go('#guides');
            //window.location.reload(true);
        });

    },

    showMapIcon (isVideo) {
      $(this.find('.site_avatar')).removeClass('map_hidden');
      if (isVideo) {
        $(this.find('.site_avatar')).addClass('white');
      } else {
        $(this.find('.site_avatar')).removeClass('white');
      }
      // TweenMax.from('.map_mobile_icon', 0.3, {opacity: 0})
    },

    hideMapIcon () {
      $(this.find('.site_avatar')).addClass('map_hidden');
    },

    showAvatar (guideIndex, isMap, isHidden) {
      this.set('guideImg', this.get('globals.guides[' + guideIndex + '].avatarImage'));
      this.set('switchImg', this.get('globals.guides[' + guideIndex + '].switchImage'));
      this.set('guideIndex', guideIndex);
      this.set('guideClass', this.get('globals.guides[' + guideIndex + '].name'));

      if (!isHidden) {
          $(this.find('.avatar')).removeClass('hidden');

      } else {
        $(this.find('.avatar')).addClass('hidden');
      }

      if (isMap) {
        $(this.find('.site_avatar')).addClass('map');
        $(this.find('.site_avatar')).removeClass('white');
      }
      else {
        $(this.find('.site_avatar')).removeClass('map');
      }

      TweenMax.from('.avatar', 0.3, { opacity: 0})
    },

    hideAvatar () {
      $(this.find('.avatar')).addClass('hidden');
    },

    onteardown() {
    }


});



// <li role="presentation"><a href="#page3?page=1&limit=50">Page 3</a></li>
// <li role="presentation"><a href="#page4/I+1234/?page=1&limit=50">Page 4</a></li>
//                <li role="presentation"><a href="#guide-video-selection">Guide Videos</a></li>

import Ractive from 'ractive';


export default Ractive.extend({
    template: `
    <svg
      id="preloader"
      height="{{radius * 2}}"
      width="{{radius * 2}}">
       <circle
         id="preloader"
         stroke="{{loaderColor}}"
         stroke-dasharray="{{circumference}} {{circumference}}"
         style="stroke-dashoffset:{{circumference}}"
         stroke-width="{{stroke}}"
         fill="transparent"
         r="{{normalizedRadius}}"
         cx="{{radius}}"
         cy="{{radius}}"/>
    </svg>

    <style>
      circle {
        transition: stroke-dashoffset 0.35s;
        transform: rotate(-270deg);
        transform-origin: 50% 50%;
      }
    </style>
  `,
    data: {
      radius: 55,
      loaderColor: '#10cfc9',
      circumference: 0,
      normalizedRadius: 0,
      stroke: 4,
      progress: 0
    },
    onrender () {
      this.set('normalizedRadius', this.get('radius') - this.get('stroke') * 2);
      this.set('circumference', this.get('normalizedRadius') * 2 * Math.PI);

      this.observe('progress', function ( newValue, oldValue, keypath ) {
        this.setProgress(newValue);
      });

      this.observe('loaderColor', function ( newValue, oldValue, keypath ) {
        const circle = document.querySelector('circle');
        circle.style.stroke = newValue;
      });
    },

    setProgress(percent) {
      const offset = this.get('circumference') - (percent / 100 * this.get('circumference'));
      const circle = document.querySelector('circle');
      circle.style.strokeDashoffset = offset;
    }
});

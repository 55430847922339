import Ractive from 'ractive';
import $ from "jquery";
import TweenMax from "gsap";
import Router from '../../src/ractive-router';
import GuideSelector from './guide-selector'

export default Ractive.extend({
    template: `
        <div id="guide-video-selection">
        <GuideSelector />
          <div class="overlay-fade"></div>
          <div class="video-panel-container">
            <div class="video-panel map" tabindex="1" data-index="0" data-color="teal" on-click="goToMap" style="cursor:url({{cursor}}), auto">
               <h1 class="adventure-text">
                You choose
                <span class="where-text">WHERE NEXT?</span>
              </h1>
              <div class="color-overlay--now-not-needed"></div>
              <div class="guide-button elu-button teal hidden">To the map</div>
              <div class="guide-img"></div>
            </div>
            <div class="visit_uel_mobile_button"><a href="https://uel.ac.uk/be-the-change" class="button blue">Visit UEL</a></div>
            <div class="map_prompt guide-select">
            <div class="map_prompt_wrapper">
              <div class="desc">
                <div class="icon"><img class="play-button" src="./images/prompt.png"/></div>
                Join our east London community
              </div>
              <a href="https://uel.ac.uk/be-the-change" class="button" target="_blank" title="Apply to UEL">Apply to UEL</a>
            </div>
          </div>
            {{#if locationIndexOne !== null }}
            <div title="{{parentGlobals.guides[routeParams.id].locations[locationIndexOne].name}}" class="video-panel video-1" tabindex="2" data-index="1" data-id={{locationIndexOne}} data-color="{{color}}" on-click="goToGuideVideo" style="cursor:url({{cursor}}), auto">
              <div class="color-overlay"></div>
              <img class="play-button" src="./images/play_{{color}}.svg"/>

              <div class="guide-button elu-button {{color}} hidden">{{parentGlobals.guides[routeParams.id].locations[locationIndexOne].name}}</div>
              <div class="guide-img" style="background:url({{parentGlobals.guides[routeParams.id].locations[locationIndexOne].imageL}}) no-repeat center"></div>
            </div>
            {{else}}
            <div title="{{parentGlobals.guides[routeParams.id].locations[locationIndexOne].name}}" class="video-panel video-1" tabindex="2" data-index="1" data-color="{{color}}" on-click="noVidGuide" style="cursor:url({{cursor}}), auto">
              <div class="color-overlay"></div>
              <img class="play-button" src="./images/play_{{color}}.svg"/>
              <div class="guide-img" style="background:url({{parentGlobals.guides[routeParams.id].defaultGuideVideoImg1}}) no-repeat center top; background-size:auto 140%"></div>
              <div title="{{parentGlobals.guides[routeParams.id].defaultGuideVideoImg1LinkText}}" class="guide-button elu-button {{color}} hidden">{{parentGlobals.guides[routeParams.id].defaultGuideVideoImg1LinkText}}</div>
            </div>
            {{/if}}
            {{#if locationIndexTwo !== null}}
            <div title="{{parentGlobals.guides[routeParams.id].locations[locationIndexTwo].name}}" class="video-panel video-2" tabindex="3" data-index="2" data-id={{locationIndexTwo}} data-color="{{color}}" on-click="goToGuideVideo" style="cursor:url({{cursor}}), auto">
              <div class="color-overlay"></div>
              <img class="play-button" src="./images/play_{{color}}.svg"/>
              <div title="{{parentGlobals.guides[routeParams.id].defaultGuideVideoImg1LinkText}}" class="guide-button elu-button {{color}} hidden">{{parentGlobals.guides[routeParams.id].locations[locationIndexTwo].name}}</div>
              <div class="guide-img" style="background:url({{parentGlobals.guides[routeParams.id].locations[locationIndexTwo].imageL}}) no-repeat center"></div>
            </div>
            {{else}}
            <div title="University of East London" class="video-panel video-2" tabindex="3" data-index="2" data-color="{{color}}" on-click="goToUel" style="cursor:url({{cursor}}), auto">
              <div class="color-overlay"></div>
              <img class="play-button" src="./images/play_{{color}}.svg"/>
              <div class="guide-img" style="background:url({{parentGlobals.guides[routeParams.id].defaultGuideVideoImg2}}) no-repeat center top;; background-size:auto 140%"></div>
              <div title="University of East London" class="guide-button elu-button {{color}} hidden" >Visit UEL</div>
            </div>
            {{/if}}
          </div>
        </div>
        <div class="page_footer home">
          <div class="copyright">Copyright ©2022 University of East London</div>
          <div class="footer_links">
            <a href="https://www.uel.ac.uk/about/governance/data-protection" target="_blank">Privacy policy</a>
            <a href="#cookie-policy">Cookie policy</a>
          </div>
        </div>
    `,
    components: {
      GuideSelector: GuideSelector
    },
    data: {
      color: 'green',
      cursor: './images/play_teal.svg',
      locationIndexOne: null,
      locationIndexTwo: null
    },
    createCookie(name, value, days) {
     var expires;
     if (days) {
       var date = new Date();
       date.setTime(date.getTime()+(days*24*60*60*1000));
       expires = "; expires="+date.toGMTString();
     }
     else {
       expires = "";
     }
     document.cookie = name+"="+value+expires+"; path=/";
   },

   // read cookie
   readCookie(name) {
     var nameEQ = name + "=";
     var ca = document.cookie.split(';');
     for(var i=0;i < ca.length;i++) {
       var c = ca[i];
       while (c.charAt(0) === ' ') {
         c = c.substring(1,c.length);
       }
       if (c.indexOf(nameEQ) === 0) {
         return c.substring(nameEQ.length,c.length);
       }
     }
     return null;
   },

   // erase cookie
    eraseCookie(name) {
     createCookie(name,"",-1);
   },

   getLocationId(location) {
     const locations = this.get('parentGlobals.guides[' + this.get('routeParams.id') + '].locations');

     for(var i = 0; i < locations.length; i++) {
       if (locations[i].id === location.id) {
         return i;
       }
     }
   },

    randomiseLocations () {

      const locations = this.get('parentGlobals.guides[' + this.get('routeParams.id') + '].locations');
      let locationsNoBlue = locations.filter(function(val) {
         return !val.isBlue;
       });

      var cookieArr = this.readCookie('videosWatched') ? JSON.parse(this.readCookie('videosWatched')) : [];

      let filteredLocations = locationsNoBlue.filter(function(val) {
         return !cookieArr.includes(val.id)
       });


      if (filteredLocations.length > 1) {
        var locationIndexOne = Math.floor(Math.random() * filteredLocations.length);

        var locationIndexTwo = () => {
          var indexTwo =  Math.floor(Math.random() * filteredLocations.length);
          if (parseInt(locationIndexOne) === parseInt(indexTwo)) {
            return locationIndexTwo();
          }
          else {
            return indexTwo
          }
        }
          this.set('locationIndexOne', this.getLocationId(filteredLocations[locationIndexOne]));

          this.set('locationIndexTwo', 1);


       }
       else if (filteredLocations.length === 1) {
          locationIndexOne = 0;
          this.set('locationIndexOne', this.getLocationId(filteredLocations[locationIndexOne]));

       }

       console.log('filteredLocations', filteredLocations)
       console.log('index1', this.get('locationIndexOne'));
       console.log('index2', this.get('locationIndexTwo'))


    },
    oninit()  {

        switch (parseInt(this.get('routeParams.id'))) {
          case 1:
            this.set('color', 'green');
            this.set('cursor', './images/play_green.svg');
            break
          case 2:
              this.set('color', 'pink')
              this.set('cursor', './images/play_pink.svg');
              break
          case 3:
              this.set('color', 'orange');
              this.set('cursor', './images/play_orange.svg');
              break
        }

    },

    showGuideSelector () {
      this.findComponent('GuideSelector').show();
    },

    isLandingAnim: true,

    onrender () {
      var ease = "power4";
      var duration = .25;
      var prevTarget = undefined;
      var animateTimeout = undefined;
      var currentTarget = undefined;
      var overDelayDuration = 100;
      var scaleTween = undefined;
      var mainTween = undefined;
      var breakPoint = 1024;

      this.randomiseLocations();

      TweenMax.to('.overlay-fade', 1, { delay: .3, opacity: 0, onComplete: ()=> {
        $(this.find('.overlay-fade')).css('pointer-events', 'none');
      }});



      this.on({
        'goToGuideVideo': (e)=> {

            //var tags = this.get('parentGlobals.guides[' + this.get('routeParams.id') + '].locations[' + $(e.node).data('id') +'].tags');

            // var tagIndex = Math.floor(Math.random() * tags.length);
            // var transVidId = 0;
            //
            // for(var i = 0; i < this.get('parentGlobals.transitionVideos').length; i++) {
            //   for(var j = 0; j < this.get('parentGlobals.transitionVideos')[i].tags.length; j++) {
            //     if (tags[tagIndex] === this.get('parentGlobals.transitionVideos')[i].tags[j]) {
            //       transVidId = this.get('parentGlobals.transitionVideos')[i].tvidId;
            //     }
            //   }
            // }

            const locations = this.get('parentGlobals.guides[' + this.get('routeParams.id') + '].locations');
            var cookieArr = this.readCookie('videosWatched') ? JSON.parse(this.readCookie('videosWatched')) : [];
            let videoId = this.get('parentGlobals.guides[' + this.get('routeParams.id') + '].locations[' + $(e.node).data('id') +'].id')
            console.log('videoId', videoId)

            cookieArr.push(videoId);
            this.createCookie('videosWatched', JSON.stringify(cookieArr), 1);

            Router.go("#video/"+ this.get('routeParams.id') +"/"+ this.get('parentGlobals.guides[' + this.get('routeParams.id') + '].locations[' + $(e.node).data('id') +'].locationvideo'));
        },
        'goToMap': (e)=> {
            Router.go("#map/"+ this.get('routeParams.id'));
        },
        'goToUel': (e)=> {
          window.open(
            this.get('parentGlobals.uelCtaLink'),
            '_blank'
          );
        },
        'noVidGuide': (e)=> {
            Router.go(this.get('parentGlobals.guides[' + this.get('routeParams.id') + '].defaultGuideVideoImg1Link'));

        },
        '*.guideSelected': (aName)=> {
          switch(aName) {
            case 'lauren':
              Router.go('#map/1');
            break;
            case 'saiful':
              Router.go('#map/2');
            break;
            case 'uma':
              Router.go('#map/3');
            break
            case 'all-maps':
              Router.go('#map/4');
            break
          }
        }
      });

      document.body.onkeyup = function(e) {
          if(e.keyCode === 13){
              //alert('tab',$(e.target)[0])
              if ($(e.target)[0].tabIndex !== -1) {
                console.log($(e.target)[0].tabIndex);
                if ($(e.target).hasClass('map')) {
                  Router.go('#map/1');
                }
                else {                  
                  Router.go("#video/"+ this.get('routeParams.id') +"/"+ this.get('parentGlobals.guides[' + this.get('routeParams.id') + '].locations[' + $(e.target).data('id') +'].locationvideo'));
                }

              }
        }
      }.bind(this);


      setTimeout(()=> {
        this.fire('showAvatar', {id: this.get('routeParams.id')});
        this.fire('showMapIcon', {IsVideo: false});
      },100);

      var self = this;
      TweenMax.from(".video-panel", {
        opacity: 0,
        x: 50,
        ease: 'power4',
        stagger: 0.15,
        onComplete: () => {
          $(self.find('.video-2')).trigger('mouseover');
          this.isLandingAnim = true;
          setTimeout(()=> {
            $(self.findAll('.video-panel')).css('pointer-events','auto');
          }, duration)

        } // 0.1 seconds between when each ".box" element starts animating
      });
      $(self.findAll('.video-panel')).css('pointer-events','none');


      TweenMax.set(this.find('.map'), {'width': '20%'});
      TweenMax.set(this.find('.video-1'), {'width': '40%'});
      TweenMax.set(this.find('.video-2'), {'width': '40%'});

      if (window.innerWidth <= breakPoint) {
        $('.guide-button').removeClass('hidden').css('opacity', 1);
      }
      else {
        $('.guide-button').addClass('hidden').css('opacity', 0);
      }

      $(window).on('resize', ()=> {
        if (window.innerWidth <= breakPoint) {
          $('.guide-button').removeClass('hidden').css('opacity', 1);
          // TweenMax.set('.guide-img', {'clearProps': 'all'});
        }
        else {
          $('.guide-button').addClass('hidden').css('opacity', 0);
        }
      });



      $(this.findAll('.video-panel')).on('mouseover', (e) => {

        if (this.isLandingAnim) {
          this.isLandingAnim = false;
          $(self.find('.video-2')).trigger('mouseout');
        }

        if (window.innerWidth <= breakPoint) {
          return;
        }

        $(e.currentTarget).find('.guide-button').removeClass('hidden');
        $(e.currentTarget).find('.guide-button').css('opacity', 0);

          // if (scaleTween) {
          //   scaleTween.kill();
          //
          // }

          if (mainTween) {
            mainTween.kill();
          }

        $(this.findAll('.video-panel')).toArray().forEach((item, index) => {
          //TweenMax.set($(item).find('.guide-img'), {className: "+=active guide-img"})
          if (index !== parseInt($(e.currentTarget).data('index'))) {
            $(item).find('.color-overlay').addClass($(e.currentTarget).data('color'));
          }
        });

        animateTimeout = setTimeout(()=> {
          var blockW = '45%';
          if ($(e.currentTarget).data('index') === 0) {
            blockW = '30%';
          }
          mainTween = TweenMax.to(e.currentTarget, duration, {'width': blockW, onComplete: ()=> {
            $(e.currentTarget).find('.guide-button').css('opacity', 0);
            $($(e.currentTarget).find('.guide-button')).css('top', '72%');
            TweenMax.to($(e.currentTarget).find('.guide-button'), {top: '70%', opacity: 1, duration: .1, ease: ease});

            //scaleTween = TweenMax.to($(e.currentTarget).find('.guide-img'),  {className: "-=guide-img", duration: .5, ease: ease})
          }});
          $('.video-panel').toArray().forEach((item, i) => {
            if ($(item).data('index') !== $(e.currentTarget).data('index')) {
              if($(item).data('index') === 0) {
                TweenMax.to(item, duration, {'width': '20%'});
              }
              else {
                TweenMax.to(item, duration, {'width': '40%'});
              }
            }
          });
        }, overDelayDuration);

        });

        $(this.find('.video-panel-container')).on('mouseleave', (e) => {

          if (window.innerWidth <= breakPoint) {
            return;
          }

          $('.video-panel').toArray().forEach((item, i) => {
              //TweenMax.to($(item).find('.guide-img'), {className:"+=active guide-img", duration: duration, ease: ease})

              if($(item).data('index') === 0) {
                TweenMax.to(item, duration, {'width': '20%'});
              }
              else {
                TweenMax.to(item, duration, {'width': '40%'});
              }
          });
        });


      $(this.findAll('.video-panel')).on('mouseout', (e) => {

        if (window.innerWidth <= breakPoint) {
          return;
        }

        clearTimeout(animateTimeout);


        $(e.currentTarget).find('.guide-button').addClass('hidden');

        prevTarget = e.currentTarget;

        $(this.findAll('.video-panel')).toArray().forEach((item, index) => {

          if (index !== parseInt($(e.currentTarget).data('index'))) {
            $(item).find('.color-overlay').removeClass($(e.currentTarget).data('color'));
          }
        });

      });
    },
    oncomplete(){
      console.log("guide....")
    },
    onteardown() {
        this.off();
        $(window).off();
        $(this.findAll('.video-panel')).off();
    }
});

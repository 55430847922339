import Ractive from 'ractive';
import 'slick-carousel';
import $ from 'jquery';

export default Ractive.extend({
    template: `
        <div id="media-modal">
          <div class="card-pop {{#if showModal}}visible{{/if}}" id="{{ location.ID }}" on-click="offClick">
              <div class="card-pop-inner">
              <div class="close-pop" data-pop="{{ location.ID }}" on-click="closeModal">Close <img src="./images/close.svg"/></div>
              <div class="slider-wrapper pop-slide slider-wrapper-thumb-slide" data-slide="{{ location.ID }}" id="popslider{{ location.ID }}">
                  {{#each location.card.slides }}
                      {{#if image}}
                          <div class="loc-slide" style="background-image:url({{ image }})" src="" >
                            <img src="./images/shim.png"/>
                          </div>
                      {{else}}
                          <div class="loc-slide" >
                              <div class="video_container">
                                <img src="./images/shim.png"/>
                                <iframe class="yt-vid" width="100%" height="100%" src="https://www.youtube.com/embed/{{video_id.split('?v=').pop()}}?enablejsapi=1&version=3&playerapiid=ytplayer" title="YouTube video player"></iframe>
                              </div>
                          </div>
                      {{/if}}
                    {{/each}}
                    </div>
                  </div>
              </div>
            </div>
    `,
    data: {
      location: undefined,
      showModal: false
    },
    slickSlider:undefined,
    onrender(){

      this.on({
        'offClick':(e)=> {
          if ($(e.original.target).attr('class') !== undefined &&
            $(e.original.target).attr('class').split(" ")[0] === "card-pop") {
              $(this.find('.slider-wrapper-thumb-slide')).slick('unslick');

              this.set('showModal', false);
            }
        },
        'closeModal':()=> {

          this.set('showModal', false);
          $(this.find('.slider-wrapper-thumb-slide')).slick('unslick');
        }
      })


    },
    showModal(event) {
      this.set({
        'location': event.card.data,
        'showModal': true
      });
      $(this.find('.slider-wrapper-thumb-slide')).slick({
            dots: true,
            infinite: true,
            arrows:true,
            prevArrow:"<button type='button' class='slick-prev pull-left'></button>",
            nextArrow:"<button type='button' class='slick-next pull-right'></button>",
            speed: 300,
        });
        $(this.find('.slider-wrapper-thumb-slide')).slick('slickGoTo', event.slickIndex, true);
    },
    oninit()  {
    },
    onteardown () {
    }
});

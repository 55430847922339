import Home from './components/home';
import GuideVideoSelection from './components/guide-video-selection';
import Video from './components/video';
import Page4 from './components/page4';
import NotFound from './components/404'
import Preloader from './components/preloader';
import Map from './components/map';
import CookiePolicy from './components/cookie-policy';
import Ractive from 'ractive';


export default {
    /* Home route designed by the index: true attribute */
    '': {
        component: Preloader,
        index: true
    },
    'guides': {
        component: Home
    },
    /* Route with required and optional path params, the route handler is a Ractive Component */
    'guide-video-selection/{id}': {
      component: GuideVideoSelection,
      callback(pathParams){
      }

    },

    // 'video/{id}/{videoId}/:transVidId:': {
    //     component: Video,
    //     callback(pathParams){
    //         console.log('Page 3 callback', pathParams);
    //     }
    // },

    'video/{id}/{videoId}': {
        component: Video,
        callback(pathParams){
            console.log('Page 3 callback', pathParams);
        }
    },

    'map/{id}/:locationId:': {
        component: Map,
        callback(pathParams){
            console.log('Page 3 callback', pathParams);
        }
    },

    'map/{id}/:?query:': {
        component: Map,
        callback(pathParams){
            console.log('Map filter callback', pathParams);
        }
    },
    /* Route handler as custom callback */
    'page2': {
        callback(pathParams){
            (document.getElementsByClassName('routeContainer')[0]).innerHTML = '<h1>Custom HTML renderer (not a component)</h1>';
            console.log('Page 2 callback', pathParams);
        }
    },
    /* You can define both a Ractive Component and a callback as a Route handlers, the callback will be executed during the Component oncomplete lifecycle event*/
    // 'page3:?query:': {
    //     component: Page3,
    //     callback(pathParams){
    //         console.log('Page 3 callback', pathParams);
    //     }
    // },
    'page4/{id}/:option::?query:': {
        component: Page4,
            callback(pathParams){
            console.log('Page 4 callback', pathParams);
        }
    },
    /* Not found Route must be set as 404 */
    '404': {
        component: NotFound
    },

    'cookie-policy': {
        component: CookiePolicy
    }
};

import Ractive from 'ractive';
import $ from "jquery";
import TweenMax from "gsap";
import Router from '../../src/ractive-router';


export default Ractive.extend({
    template: `
        <div id="home">
          <div class="guide-panel-container">
            <div class="guide-panel map" data-index="0" data-color="teal" on-click="goToMap" tabindex="" title="To the map">
              <h1 class="adventure-text">
                Discover your
                <span class="where-text">East London</span>
              </h1>
              <div class="color-overlay-not-needed"></div>
              <div class="guide-button elu-button teal hidden" title="map of east London">To the map</div>
              <div class="guide-img"></div>
            </div>
            <div class="guide-panel guide-1" data-index="1" data-color="green" on-click="goToGuideVideos" tabindex="2" title="Follow Lauren" alt="Follow Lauren">
              <div class="color-overlay"></div>
              <img class="play-button" src="./images/play_green.svg" alt="Play"/>

              <div class="guide-button elu-button green hidden" title="Follow Lauren profile">Follow Lauren</div>
              <div class="guide-img"></div>
            </div>
            <div class="guide-panel guide-2" data-index="2" data-color="pink" on-click="goToGuideVideos" tabindex="3" title="Follow Saiful" alt="Follow Saiful">
              <div class="color-overlay"></div>
              <img class="play-button" src="./images/play_pink.svg" alt="Play"/>
              <div class="guide-button elu-button pink hidden" title="Follow Saiful profile">Follow Saiful</div>
              <div class="guide-img"></div>
            </div>
            <div class="guide-panel guide-3" data-index="3" data-color="orange" on-click="goToGuideVideos" tabindex="4" title="Follow Uma" alt="Follow Uma">
              <div class="color-overlay"></div>
              <img class="play-button" src="./images/play_orange.svg" alt="Play"/>
              <div class="guide-button elu-button orange hidden" title="Follow Uma profile">Follow Uma</div>
              <div class="guide-img"></div>
            </div>
          </div>
        </div>
        <div class="page_footer home">
          <div class="copyright">Copyright ©2022 University of East London</div>
          <div class="footer_links">
            <a href="https://www.uel.ac.uk/about/governance/data-protection" target="_blank">Privacy policy</a>
            <a href="#cookie-policy">Cookie policy</a>
          </div>
        </div>
    `,
    oninit() {
        console.log('Home init');
    },
    prevPanel: undefined,
    currPanel: undefined,

    onrender () {
      var ease = "power4";
      var duration = .2;
      var prevTarget = undefined;
      var animateTimeout = undefined;
      var currentTarget = undefined;
      var overDelayDuration = 100;
      var scaleTween = undefined;
      var mainTween = undefined;
      var breakPoint = 1024;

      this.on({
        'goToGuideVideos': (e)=> {
          //Router.go("#guide-video-selection/" + $(e.node).data('index'));
          Router.go('#video/' + $(e.node).data('index') +'/'+ this.get('parentGlobals.guides[' + $(e.node).data('index') + '].profileVideo'));
        },
        'goToMap': (e)=> {
          localStorage.removeItem("ActiveFilters");
          Router.go("#map/4");
        }
      });

      setTimeout(()=> {
        this.fire('hideAvatar');
        this.fire('showMapIcon', {isVideo:false});
      },100);


      TweenMax.set(this.findAll('.guide-panel'), {'width': '25%'});
      TweenMax.from(".guide-panel", {
        opacity: 0,
        x: 30,
        ease: 'power4',
        stagger: 0.25
      });

      if (window.innerWidth <= breakPoint) {
        $('.guide-button').removeClass('hidden').css('opacity', 1);
      }
      else {
        $('.guide-button').addClass('hidden').css('opacity', 0);
      }

      $(window).on('resize', ()=> {
        if (window.innerWidth <= breakPoint) {
          $('.guide-button').removeClass('hidden').css('opacity', 1);
          //TweenMax.set('.guide-img', {'clearProps': 'all'});
        }
        else {
          $('.guide-button').addClass('hidden').css('opacity', 0);
        }
      });

      $(this.findAll('.guide-panel')).on('mouseover', (e) => {

        if (window.innerWidth <= breakPoint) {
          return;
        }

        this.currPanel = e.currentTarget;

        $(e.currentTarget).find('.guide-button').removeClass('hidden');

        $(e.currentTarget).find('.guide-button').css('opacity', 0);

          // if (scaleTween) {
          //   scaleTween.kill();
          //
          // }

          if (mainTween) {
            mainTween.kill();
          }

        $(this.findAll('.guide-panel')).toArray().forEach((item, index) => {
          //TweenMax.set($(item).find('.guide-img'), {'background-size': 'auto 140%'})
          if (index !== parseInt($(e.currentTarget).data('index'))) {
            $(item).find('.color-overlay').addClass($(e.currentTarget).data('color'));
          }
        });

        animateTimeout = setTimeout(()=> {

          console.log('btn',e.target)
          mainTween = TweenMax.to(e.currentTarget, duration, {'width': '30%', ease: ease, onComplete: ()=> {

              $(e.currentTarget).find('.guide-button').css('opacity', 0);
              $($(e.currentTarget).find('.guide-button')).css('top', '72%');
              TweenMax.to($(e.currentTarget).find('.guide-button'), {top: '70%', opacity: 1, duration: .1, ease: ease});
            //scaleTween = TweenMax.to($(e.currentTarget).find('.guide-img'), {'background-size': 'auto 120%', duration: .4, ease: ease})
          }});
          $('.guide-panel').toArray().forEach((item, i) => {
            if ($(item).data('index') !== $(e.currentTarget).data('index')) {
              TweenMax.to(item, duration, {'width': '25%'});
            }
          });
        }, overDelayDuration);

        });

        $(this.find('.guide-panel-container')).on('mouseleave', (e) => {

          if (window.innerWidth <= breakPoint) {
            return;
          }

          this.prevPanel = undefined;

          $('.guide-panel').toArray().forEach((item, i) => {
              //TweenMax.to($(item).find('.guide-img'), {'background-size': 'auto 140%', duration: duration, ease: ease})
              TweenMax.to(item, duration, {'width': '25%'});
          });
        });


      $(this.findAll('.guide-panel')).on('mouseout', (e) => {

        if (window.innerWidth <= breakPoint) {
          return;
        }

        clearTimeout(animateTimeout);

        prevTarget = e.currentTarget;

        $(this.findAll('.guide-panel')).toArray().forEach((item, index) => {

          if (index !== parseInt($(e.currentTarget).data('index'))) {
            $(item).find('.color-overlay').removeClass($(e.currentTarget).data('color'));
          }
        });

           $(e.currentTarget).find('.guide-button').addClass('hidden');

      });

      document.body.onkeyup = function(e) {
          if(e.keyCode === 13){
              //alert('tab',$(e.target)[0])
              if ($(e.target)[0].tabIndex !== -1) {
                console.log($(e.target)[0].tabIndex);
                if ($(e.target).hasClass('map')) {
                  Router.go('#map/1');
                }
                else {
                  Router.go('#video/' + $(e.target).data('index') +'/'+ this.get('parentGlobals.guides[' + $(e.target).data('index') + '].profileVideo'));
                }

              }
        }
      }.bind(this)
    },
    onteardown() {
      $(window).off();
      this.off();
        console.log('Home teardown');
    }
});

  // <div>App: {{parentGlobals.app}}</div>


import Ractive from 'ractive';
import TopNav from './components/top-nav';
import Footer from './components/footer.js';
import routesConfig from './routes';
import Router from '../src/ractive-router';
import $ from "jquery";
import "./styles/styles.scss";
import MediaModal from "./components/media-modal";


//Root App
window.ractive = new Ractive({
        debug: false,
        el: '#root',
        data: {
            routesConfig,
            globals: '',
            loading: true
        },
        components: {
            TopNav,
            MediaModal,
            Router,
            Footer
        },
        template: `
        {{#unless loading}}
        <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css"/>

        <div class="outer-container">
            <TopNav />
            <div class="site_cookie_notice flex">
              <div class="col col_left">
                <img src="./images/cookie.svg" alt="">
              </div>
              <div class="col col_right">
                <p>We use cookies to give you the best online experience. Please let us know if you agree to all of these cookies.</p>
                <div class="btn-group flex">
                  <button class="btn link dark" on-click="manageCookiesClicked">manage settings</button>
                  <button class="btn white" on-click="agreeClicked">I agree</button>
                </div>
              </div>
            </div>
            <div class="site_cookie_settings hidden">
              <div class="modal_inner">
                <div class="modal_wrapper">
                  <div class="cookie_section">
                    <div class="section_title flex">
                      <div class="icon"></div>
                      <h3>Necessary</h3>
                      <div class="checkbox_main">
                        <label for="cookie1" class="checker_wrapper fixed">
                          <span style="opacity: 0;">Required Cookies</span>
                          <input type="hidden" name="cookie1" id="cookie1" readonly>
                          <span class="switch"></span>
                        </label>
                      </div>
                    </div>
                    <p>We need to use these cookies to make our website work, for example, so you can get promotions awarded to your account.</p>
                  </div>
                  <div class="cookie_section">
                    <div class="section_title flex">
                      <div class="icon"></div>
                      <h3>Analytics</h3>
                      <div class="checkbox_main">
                        <input type="checkbox" name="cookie2" id="cookie2" checked>
                        <label for="cookie2" class="checker_wrapper" on-click="setAnalytics">
                          <span style="opacity: 0;">Google Analytics</span>
                          <span class="switch"></span>
                        </label>
                      </div>
                    </div>
                    <p>These allow us to recognise and count the number of visitors to our website, and see how visitors browse our website, so we can improve it where necessary.</p>
                    <p>These also allow us to see what pages and links you have visited so we can provide more relevant ads. We may share this information with other organisations, such as Google, Facebook and LinkedIn, for the same purpose.</p>
                  </div>

                  <div class="btn-group flex">
                    <button class="btn link dark">Find out more</button>
                    <button class="btn white" on-click="saveChangesClicked">Save changes</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
            <MediaModal />
          <Router routesConfig={{routesConfig}} app={{this}} />
        </div>
      </div>
      {{/unless}}
    `,
        construct(){

        },
        oninit(){
            //console.log('App init');

        },
        isAnalyticsSet: true,

        onrender() {
            if (!this.readCookie('uel')) {
                setTimeout(() => {
                  $('.site_cookie_notice').addClass('active')
                }, 1000)
            }

            this.on({
                'agreeClicked': (e)=> {
                    this.checkAndSetCookie();
                },
                'setAnalytics': (e)=> {
                    this.setAnalytics();
                },
                'manageCookiesClicked': ()=> {
                    $(this.find('.site_cookie_settings')).removeClass('hidden');
                },
                'saveChangesClicked': ()=> {
                    $(this.find('.site_cookie_settings')).addClass('hidden');
                    this.checkAndSetCookie();
                }
            });
        },
        setAnalytics () {
            this.isAnalyticsSet = !this.isAnalyticsSet;
        },

        checkAndSetCookie() {
            // console.log(this.readCookie('uel'));
            if (!this.readCookie('uel')) {
                this.createCookie('uel', 'analytics='+this.isAnalyticsSet.toString(), 7);
                $('.site_cookie_notice').addClass('hidden');

                // var allowgtm = this.readCookie('uel');

                // if(allowgtm == "analytics=true") {
                //   loadjs("https://www.googletagmanager.com/gtm.js?id=GTM-KXXG7S");
                // }
            }
        },
        createCookie(name, value, days) {
            var expires;
            if (days) {
                var date = new Date();
                date.setTime(date.getTime()+(days*24*60*60*1000));
                expires = "; expires="+date.toGMTString();
            }
            else {
                expires = "";
            }
            document.cookie = name+"="+value+expires+"; path=/";
        },

        // read cookie
        readCookie(name) {
            var nameEQ = name + "=";
            var ca = document.cookie.split(';');
            for(var i=0;i < ca.length;i++) {
                var c = ca[i];
                while (c.charAt(0) === ' ') {
                    c = c.substring(1,c.length);
                }
                if (c.indexOf(nameEQ) === 0) {
                    return c.substring(nameEQ.length,c.length);
                }
            }
            return null;
        },

        // erase cookie
        eraseCookie(name) {
            createCookie(name,"",-1);
        },

        updateComponentEvents(component) {
            component.off('showAvatar');
            component.off('hideAvatar');
            component.off('hideMapIcon');
            component.off('showMapIcon');
            component.off('enterClicked1');
            component.off('mapCardClicked');
            component.off('filtersApplied');

            component.on({
                'enterClicked1': (e)=> {
                    this.checkAndSetCookie();
                },
                'showAvatar':(event)=> {
                    this.findComponent('TopNav').showAvatar(parseInt(event.id), event.isMap, event.isHidden);
                },
                'showMapIcon':(event)=> {
                    this.findComponent('TopNav').showMapIcon(event.isVideo);
                },
                'hideMapIcon':(event)=> {
                    this.findComponent('TopNav').hideMapIcon();
                },
                'hideAvatar':()=> {
                    this.findComponent('TopNav').hideAvatar();
                },
                '*.mapCardClicked':(event)=> {
                  console.log('ee',event)
                  this.findComponent('MediaModal').showModal(event);
                }

            });
            this.findComponent('TopNav').off('mapIconClicked1');
            this.findComponent('TopNav').off('avatarClicked1');
            this.findComponent('TopNav').on({
                'avatarClicked1': ()=> {
                    if (component.showGuideSelector) {
                        component.showGuideSelector();
                    }
                },
                'mapIconClicked1': (e)=> {
                    Router.go('#map/'+ e.guideIndex);
                }
            });

        },
        oncomplete(){

        }
    });



// var requestOptions = {
//     method: 'GET',
//     redirect: 'follow'
// };
// fetch("https://stage.ninjasforhire.co.za/uelfe/wp-json/api/uel_locations", requestOptions)
//     .then(response => response.json())
//     .then(result => console.log(result))
//     .then(result => ractive.set('globals', result ))
//     .catch(error => console.log('error', error));

$.get('https://wherenext.uel.ac.uk/admin/wp-json/api/uel_locations', function( text ) {
    ractive.set('globals', text);
    ractive.set('loading', false);
    console.log("we are done loading");
});
const tag = document.createElement('script');
tag.src = 'https://www.youtube.com/iframe_api';

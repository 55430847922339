import Ractive from 'ractive';

export default Ractive.extend({
    template: `
        <div class="cookie_policy">
            <div class="cookie_policy_wrapper">
                <div class="cookie_header" on-click="close">
                      <span class="label">Close</span>
                      <span class="icon"><img src="./images/close_button.svg" /></span>
                </div>
                <div class="cookie_policy_inner">
                    <div class="cookie_inner_header">
                        <div class="icon"><img src="./images/cookie.svg" alt=""></div>
                        <h3>Cookies</h3>
                    </div>

                    <div class="cookie_inner_body">
                       <p>When we use words like “us”, “we”, “our” we mean the University of East London. We are the data controller of the data collected by cookies on our website.</p>
                        <p>Our website is https://uel.ac.ukl incl. our sub-domains, we own and operate. When we refer to “you” we mean you as a user or visitor of our website. This Notice is part of our privacy Notice. Our use of cookies may include processing of your personal data and we therefore recommend that you read our data protection notice.</p>
                        <h4>What do I need to know about cookies?</h4>
                        <p>There are different types of cookies and they are used for different purposes.</p>
                        <p>Below you can read about what a cookie is, the difference between first and third party cookies and session cookies vs. persistent cookies and what types of cookies we use on our website and why.</p>
                        <h4>What are cookies?</h4>
                        <p>A cookie is a small piece of data that a website stores on your device when you visit it and which is then read when you later revisit the site. The word “cookies” in this Notice and the consent also refers to other forms of automatic collection of data, e.g. Flash-cookies (Local Shared Objects), Web Storage (HTML5), Javascripts or cookies placed by other software.</p>
                        <p>A cookie may contain information about the website itself, a unique identifier that allows the site to recognise your web browser when you return to the website, additional data that serves the purpose of the cookie, and the lifespan of the cookie itself.</p>
                        <p>The word “cookies” or “cookie data” also covers information about IP and MAC addresses and other information about your device collected by said technologies.</p>
                        <p>Cookies are used to enable certain features (e.g. logging in), to track site usage (e.g. analytics), to store your user settings (e.g. timezone, notification preferences), and to personalise your content (e.g. advertising, language).</p>
                        <h4>Session cookies vs. persistent cookies</h4>
                        <p>Session cookies only last as long as your online session. This means that they will disappear from your computer or device when you close your browser. They are therefore also sometimes referred to as temporary cookies. Typically, session cookies are used to remember what a user put in their basket when they are browsing a website.</p>
                        <p>Persistent cookies are different. These cookies are sometimes called permanent cookies. They will stay on your computer or device after you close your browser. These types of cookies will expire according to the time specified in the cookie. You can see the specific duration of each persistent cookie below.</p>
                        <h4>What’s the difference between first and third party cookies?</h4>
                        <p>First party cookies are cookies that are set by the website that you are visiting and it’s only this website that can access and read these cookies.</p>
                        <p>Third party cookies are set by someone other than the owner of the website you’re visiting. As an example, some pages have content from other sites like YouTube. YouTube may set their own cookies on your browser when you play the video from YouTube. Cookies set by other sites and companies (i.e. third parties) can be used to track you on other websites that use the same third-party service.</p>
                        <p>We may engage third parties to assist with maintenance, operation, creation or functionality of our website, e.g. analytics providers and content partners. We grant these third parties’ access to selected information to perform specific tasks on our behalf.</p>
                        <p>We are, as a general rule, joint controller with providers of third-party cookies for the collection of personal data via such cookies and the disclosure to the provider. The provider of third-party cookies is data controller for the processing taking place after receiving the personal data from us. You can read more about the extent of our joint controllership with providers of third-party cookies in our privacy policy.</p>
                        <p>We recommend reading the provider’s privacy policy which can be found through links in the tables below, where you can also see which cookies on our website are first party cookies and third-party cookies.</p>
                        <h3>How we use cookies on the University of East London’s Where Next microsite</h3>
                        <h4>Necessary cookies</h4>
                        <p>Necessary cookies are required for the basic functionality of our website to work. We use necessary cookies to make it possible for you to only have to enter your username and password once during a visit to our website.</p>
                        <p>We also use necessary cookies to help with ensuring that you are given the option to accept or reject cookies, block non-necessary cookies from working until you give consent, and remember your cookie settings and choices. The cookies also help keep track of, if, and when, you gave consent to analytical cookies, T&amp;Cs and email marketing.</p>
                        <p>Necessary cookies are also used for payment processing.</p>
                        <p>It is not necessary to accept nor possible to reject the use of necessary cookies as they are core for the functionality of our website.</p>
                        <h4>Analytical cookies</h4>
                        <p>Analytical cookies gather statistics. We use this information to make our website even better. The information collected via the analytical cookies track how you use our website during your visit. It helps us understand visitor usage patterns, identify, and diagnose problems or errors you may encounter, and make better strategic decisions in improving the website experience.</p>
                        <p>We will only set analytical cookies on your device if you give us your consent.</p>
                        <p>Marketing cookies</p>
                        <p>Marketing cookies are used in determining what promotional content is more relevant and appropriate to you and your interests. Websites may use them to deliver targeted advertising or to limit the number of times you see an advertisement. This helps companies improve the effectiveness of their campaigns, and the quality of content presented to you. These cookies may be set by the website you’re visiting (first party) or by third-party services. Marketing cookies set by a third-party service may be used to track you on other websites that use the same third-party service.</p>
                        <p>We will only set marketing cookies on your device if you give us your consent.</p>
                        <h4>How you can change your cookie settings, including opting out?</h4>
                        <p>As part of our cookie solution, we always ask for your consent to cookies, except for necessary cookies, before placing cookies on your device.</p>
                        <p>We also always give you the option to change your consent. If you at one point gave consent to non-necessary cookies on our website, you can always change which cookies you will give consent to. Just look for the shield or the cookie settings link on our website. If you click the shield or the cookie settings link, your cookie settings will appear, allowing you to always change your settings and reject cookies.</p>
                        <p>It is also possible to instruct your browser to refuse cookies from our website. Most browsers are configured to accept cookies by default, but you can update these settings to either refuse cookies altogether, or to notify you when a website is trying to set or update a cookie. If you use multiple browsers and wish to block cookies or change or withdraw your consent, remember to do this in all browsers.</p>
                        <p>If you browse websites from multiple devices, you may also need to update your settings on each individual device.</p>
                        <p>Although some cookies can be blocked with little impact on your experience of a website, blocking all cookies may mean you are unable to access certain features and content on the site.</p>
                        <h3>Types of cookies we use:</h3>
                        <h4>Classification of cookies:</h4>
                        <h3><em>Analytics:</em></h3>
                        <p><strong>ga</strong><br>
                        Used to distinguish users.</p>
                        <p><strong>_gid</strong><br>
                        Used to distinguish users.</p>
                        <p><strong>_ga_</strong><br>
                        Used to persist session state.</p>
                        <p><strong>_gac_gb_</strong><br>
                        Contains campaign related information. If you have linked your Google Analytics and Google Ads accounts, Google Ads website conversion tags will read this cookie unless you opt-out. Learn more</p>
                        <p><strong>__utma</strong><br>
                        Used to distinguish users and sessions. The cookie is created when the javascript library executes and no existing __utma cookies exists. The cookie is updated every time data is sent to Google Analytics.</p>
                        <p><strong>__utmt</strong><br>
                        Used to throttle request rate.</p>
                        <p><strong>__utmb</strong><br>
                        Used to determine new sessions/visits. The cookie is created when the javascript library executes and no existing __utmb cookies exists. The cookie is updated every time data is sent to Google Analytics</p>
                        <p><strong>__utmc</strong><br>
                        Not used in ga.js. Set for interoperability with urchin.js. Historically, this cookie operated in conjunction with the __utmb cookie to determine whether the user was in a new session/visit.</p>
                        <p><strong>__utmz</strong><br>
                        Stores the traffic source or campaign that explains how the user reached your site. The cookie is created when the javascript library executes and is updated every time data is sent to Google Analytics.</p>
                        <p><strong>__utmv</strong><br>
                        Used to store visitor-level custom variable data. This cookie is created when a developer uses the _setCustomVar method with a visitor level custom variable. This cookie was also used for the deprecated _setVar method. The cookie is updated every time data is sent to Google Analytics.</p>
                        <p><strong>_ga</strong><br>
                        Google Analytics cookie. The cookie is used to calculate visitor, session, campaign data and keep track of site usage for the site’s analytics report. The cookies store information anonymously and assign a randomly generated number to identify unique visitors.</p>
                        <p><strong>_gid</strong><br>
                        Google Analytics cookie. Used to store information of how visitors use a website and helps in creating an analytics report of how the website is doing. The data collected including the number visitors, the source where they have come from, and the pages visited in an anonymous form.</p>
                        <h3><em>Necessary</em></h3>
                        <p><strong>Videos watched</strong><br>
                        What we use to keep track of the videos users have already watched</p>
                        <p><strong>UEL</strong><br>
                        what we use to check if users have accepted the cookies and if they are allowing analytics (or not)</p>
                        <p><strong>utmt_player</strong><br>
                        To store and track audience reach.</p>
                        <h3>Third-party cookies and Personalisation</h3>
                        <p>Personalisation creates first-party cookies by default, using the domain of the website the visitor is viewing. Third-party cookies are optional in Personalization, and if enabled, use the acquia.com domain.</p>
                        <h3>Updating this Notice</h3>
                        <p>We may update this notice from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal or regulatory reasons. Please therefore re-visit this notice regularly to stay informed about our use of cookies and related technologies.</p>
                        <h3>IF you have questions or would like more information about how we use cookies on our website, contact us:</h3>
                        <p>You can email us at: dpo@uel.ac.uk.<br>
                        Information Assurance Office<br>
                        University of East London<br>
                        4-6 University Way<br>
                        E16 2RD<br>
                        London<br>
                        United Kingdom</p>
                    </div>
                </div>
            </div>
        </div>
    `,
    oninit() {
        console.log('404 init');
    },
    onrender () {
        this.on('close', ()=> history.back());
        setTimeout(()=> {
          this.fire('hideAvatar');
        },100);

    },
    onteardown() {
        console.log('404 teardown');
    }
});
